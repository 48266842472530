import { __awaiter } from "tslib";
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { subSeconds, addSeconds, differenceInMilliseconds, formatISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useOrderQuery, useResetOrderPaymentBlockMutation } from '@websky/graphql';
import { getOrder } from '@websky/core/src/Checkout/store/order/selectors';
import { fillOrder } from '@websky/core/src/Checkout/store/order/actions';
import { setOrder } from '@websky/core/src/Modules/Exchange/store/order/actions';
import { get, set } from '@websky/core/src/cache';
import { format } from '@websky/core/src/utils';
import RootPaymentForm from '@websky/core/src/PaymentForm';
import SimpleLoader from '@websky/core/src/SimpleLoader';
import css from './PaymentForm.css';
const errorIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM9 15V13H11V15H9ZM9 5V11H11V5H9Z", fill: "#E02222" })));
const MIN_TIME_LIMIT_IN_MINUTES = 10;
const PAYMENT_TIME_LIMIT = 'PAYMENT_TIME_LIMIT';
const PaymentForm = props => {
    var _a;
    const { t } = useTranslation('Exchange');
    const dispatch = useDispatch();
    const order = useSelector(getOrder);
    const orderId = order.id;
    const history = useHistory();
    const match = useRouteMatch();
    const [isLoading, setIsLoading] = React.useState(false);
    const { refetch: refetchOrder } = useOrderQuery({
        fetchPolicy: 'no-cache',
        skip: true
    });
    const [resetOrderPayment] = useResetOrderPaymentBlockMutation();
    const paymentMethods = [
        props.credit,
        props.card,
        props.itransfer,
        props.googlePay,
        props.kaspi,
        props.cash,
        props.invoice,
        props.qiwi,
        props.sbol,
        props.fps
    ];
    const baseUrl = match.url;
    const isExchange = baseUrl.includes('exchange');
    const paymentId = React.useMemo(() => {
        var _a, _b;
        if (isExchange) {
            return (_b = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) === null || _b === void 0 ? void 0 : _b.id;
        }
        return order.id;
    }, [order, isExchange]);
    const timeLimitSessionKey = `${PAYMENT_TIME_LIMIT}_${paymentId}`;
    const timeLimitFromSession = get(timeLimitSessionKey);
    const timeLimitInSeconds = timeLimitFromSession || ((_a = paymentMethods.find(method => !!method)) === null || _a === void 0 ? void 0 : _a.timelimitInSeconds);
    React.useEffect(() => {
        if (!timeLimitFromSession) {
            set(timeLimitSessionKey, timeLimitInSeconds);
        }
    }, []);
    const timeLimit = React.useMemo(() => {
        var _a, _b;
        if (isExchange) {
            return (_b = (_a = order.exareInfo) === null || _a === void 0 ? void 0 : _a.lastExchange) === null || _b === void 0 ? void 0 : _b.timelimit;
        }
        return order.timelimit;
    }, [order, isExchange]);
    const minTimeLimitInSeconds = React.useMemo(() => {
        if (!timeLimitInSeconds) {
            return undefined;
        }
        const minTimeLimit = MIN_TIME_LIMIT_IN_MINUTES * 60;
        if (timeLimitInSeconds < minTimeLimit) {
            // time limit slightly shorter to redirect the user to the order in advance
            return timeLimitInSeconds * 0.85;
        }
        return minTimeLimit;
    }, [timeLimitInSeconds]);
    const timeLimitDate = React.useMemo(() => {
        if (!timeLimit) {
            return undefined;
        }
        const timeLimitDate = new Date(timeLimit);
        if (!minTimeLimitInSeconds) {
            return timeLimitDate;
        }
        const paymentStartDate = subSeconds(timeLimitDate, timeLimitInSeconds);
        return addSeconds(paymentStartDate, minTimeLimitInSeconds);
    }, [timeLimit, timeLimitInSeconds, minTimeLimitInSeconds]);
    const redirectUrl = React.useMemo(() => {
        if (isExchange) {
            return baseUrl.replace('payment', 'request');
        }
        return baseUrl.replace('payment', 'order');
    }, [baseUrl, isExchange]);
    const onFillOrder = React.useCallback((order) => {
        if (isExchange) {
            dispatch(setOrder(order));
        }
        else {
            dispatch(fillOrder(order));
        }
    }, [isExchange, dispatch]);
    const onBackToOrder = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            yield resetOrderPayment({ variables: { id: orderId } });
            const { data } = yield refetchOrder({ id: orderId });
            if (!(data === null || data === void 0 ? void 0 : data.Order)) {
                return;
            }
            onFillOrder(data.Order);
            history.push(redirectUrl);
            localStorage.removeItem(timeLimitSessionKey);
        }
        catch (e) {
            console.log(e);
        }
        setIsLoading(false);
    }), [orderId, timeLimitSessionKey, redirectUrl, resetOrderPayment, refetchOrder, onFillOrder]);
    React.useEffect(() => {
        let timeoutId;
        if (timeLimitDate !== undefined) {
            const millisecondsUntilResetPayment = differenceInMilliseconds(timeLimitDate, new Date());
            timeoutId = setTimeout(onBackToOrder, millisecondsUntilResetPayment);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeLimitDate]);
    return (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null),
        isExchange && (React.createElement("div", { className: css.timelimit },
            errorIcon,
            t('Order must be paid by {{time}} on {{date}}', {
                time: format(timeLimitDate, 'HH:mm'),
                date: format(timeLimitDate, 'd MMMM yyyy')
            }))),
        React.createElement(RootPaymentForm, Object.assign({}, props, { order: props.order
                ? Object.assign(Object.assign({}, props.order), { timelimit: timeLimitDate ? formatISO(timeLimitDate) : props.order.timelimit }) : null }))));
};
export default PaymentForm;
