import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import OrderStatusComponent from '@websky/core/src/OrderCard/components/OrderStatus/OrderStatus';
import { OrderCardContext } from '@websky/core/src/OrderCard/components/OrderCard';
import Money from '@websky/core/src/Money';
import css from './OrderStatus.css';
const OrderStatus = props => {
    var _a;
    const { t } = useTranslation('OrderCard');
    const { order } = useContext(OrderCardContext);
    return (React.createElement(OrderStatusComponent, Object.assign({}, props, { buttons: React.createElement("div", { className: css.total },
            t('Total'),
            ": ",
            React.createElement(Money, { money: (_a = order.price) === null || _a === void 0 ? void 0 : _a.total })) })));
};
export default OrderStatus;
