import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '@websky/core/src/theme';
import { useConfig } from '@websky/core/src/context';
import { format, MOBILE_MIN_WIDTH } from '@websky/core/src/utils';
import { CloseClear } from '@websky/core/src/Icons';
import { DatepickerTab } from '@websky/core/src/SearchForm/components/SearchForm/Segments/Segment/Datepicker/Datepicker';
import { tryParseDate } from '@websky/core/src/SearchForm/components/SearchForm/Segments/Segment/Datepicker/Value/utils';
import ManualInput from '@websky/core/src/SearchForm/components/SearchForm/Segments/Segment/Datepicker/Value/ManualInput/ManualInput';
import theme from './Value.css';
import MediaQuery from 'react-responsive';
const Value = ({ isOpen, open, close, setRouteType, segmentId, withDateBack, routeType, dateBack, dateTo, onClear, onDateSelect, setIsRouteTypeSetManually, onClickDatepickerTabReturn, onClickDatepickerTabOutbound }) => {
    const { Datepicker: css } = useTheme('SearchForm');
    const { t } = useTranslation('SearchForm');
    const { mobileFormType, proMode } = useConfig().SearchForm;
    const alternative = mobileFormType !== 'common';
    const onManualDateEnter = (value) => {
        const parseDate = tryParseDate(value);
        if (parseDate) {
            onDateSelect(parseDate, routeType === DatepickerTab.Return);
        }
    };
    const handleRouteTypeChange = useCallback((e, routeType) => {
        if (isOpen) {
            e.preventDefault();
            e.stopPropagation();
            setRouteType(routeType);
            setIsRouteTypeSetManually(true);
        }
        if (routeType === DatepickerTab.Return && onClickDatepickerTabReturn) {
            onClickDatepickerTabReturn();
        }
        if (routeType === DatepickerTab.Outbound && onClickDatepickerTabOutbound) {
            onClickDatepickerTabOutbound();
        }
    }, [routeType, isOpen, dateTo, dateBack]);
    return (React.createElement("div", { className: cn(css.dates, `${css.dates}_${segmentId}`, {
            [css.oneDate]: !withDateBack,
            [css.dates_focused]: isOpen,
            [css.alternative]: alternative
        }), onClick: () => {
            setRouteType(DatepickerTab.Outbound);
            isOpen ? close() : open();
        } },
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: cn(css.dates__to, {
                    [css.dates__to_focused]: isOpen && routeType === DatepickerTab.Outbound
                }) },
                React.createElement(ManualInput, { onEnter: onManualDateEnter, showInput: isOpen && proMode && routeType === DatepickerTab.Outbound, inputClassName: css.date__input },
                    dateTo && (React.createElement("div", { onClick: e => {
                            handleRouteTypeChange(e, DatepickerTab.Outbound);
                        } },
                        format(dateTo, 'dd MMM'),
                        React.createElement("span", { className: css.dow }, dateBack || isOpen ? format(dateTo, 'eeeeee') : format(dateTo, 'eeee')))),
                    !dateTo && isOpen ? (React.createElement("span", { onClick: e => handleRouteTypeChange(e, DatepickerTab.Outbound) }, t('Outbound'))) : (React.createElement("span", { className: css.placeholder }, t('Outbound'))))),
            withDateBack && (isOpen || dateBack) && (React.createElement("div", { className: cn(css.dates__back, {
                    [css.dates__back_focused]: isOpen && routeType === DatepickerTab.Return
                }) },
                React.createElement(ManualInput, { onEnter: onManualDateEnter, showInput: isOpen && proMode && routeType === DatepickerTab.Return, inputClassName: cn(css.date__input, css.date__input_back) },
                    dateBack && (React.createElement("div", { onClick: e => {
                            handleRouteTypeChange(e, DatepickerTab.Return);
                        } },
                        format(dateBack, 'dd MMM'),
                        React.createElement("span", { className: css.dow }, format(dateBack, 'eeeeee')))),
                    React.createElement("span", { className: css.placeholder, onClick: e => handleRouteTypeChange(e, DatepickerTab.Return) }, t('Date_from')))))),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: cn(css.dates__to, {
                    [css.dates__to_focused]: isOpen && routeType === DatepickerTab.Outbound
                }) },
                dateTo && (React.createElement("div", null,
                    format(dateTo, 'dd MMM'),
                    React.createElement("span", { className: css.dow }, dateBack || isOpen ? format(dateTo, 'eeeeee') : format(dateTo, 'eeee')))),
                !dateTo && isOpen ? (React.createElement("span", { onClick: e => handleRouteTypeChange(e, DatepickerTab.Outbound) }, t('Outbound'))) : (React.createElement("span", { className: css.placeholder }, t('Outbound')))),
            withDateBack && (isOpen || dateBack) && (React.createElement("div", { className: cn(css.dates__back, {
                    [css.dates__back_focused]: isOpen && routeType === DatepickerTab.Return
                }) },
                dateBack && (React.createElement("div", null,
                    format(dateBack, 'dd MMM'),
                    React.createElement("span", { className: css.dow }, format(dateBack, 'eeeeee')))),
                React.createElement("span", { className: css.placeholder }, t('Date_from'))))),
        (dateTo || dateBack) && (React.createElement("div", { className: cn(css.returnDelete, theme.clear), onClick: e => {
                e.preventDefault();
                e.stopPropagation();
                onClear();
            } }, CloseClear))));
};
export default Value;
