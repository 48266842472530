import i18n from 'i18next';
import { TravellerTypes } from '@websky/core/src/Traveller/types';
import { getPassengerNames } from '@websky/core/src/utils';
const getCustomUserCardHeaderLabel = (profile, travellerType) => {
    const { firstName, lastName } = getPassengerNames(profile);
    if (travellerType === TravellerTypes.CompanionEmpty) {
        return i18n.t('Account:Add companion');
    }
    if (firstName && lastName) {
        return `${lastName} ${firstName}`;
    }
    return i18n.t('Account:Welcome!');
};
export default getCustomUserCardHeaderLabel;
