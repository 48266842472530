import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Passengers from '@websky/core/src/Checkout/components/Checkout/routes/Passengers';
import { getIsFareLockSelected } from '@websky/core/src/Checkout/store/order/selectors';
import { ExareStatus } from '@websky/graphql';
const PassengersRoute = props => {
    const { order, baseUrl } = props;
    const isFareLockSelected = useSelector(getIsFareLockSelected);
    if (order.exareInfo.lastExchange && order.exareInfo.lastExchange.status === ExareStatus.AwaitingPayment) {
        return React.createElement(Redirect, { to: `${baseUrl}/exchange/payment` });
    }
    else if (order.isBlockedForPayment && !isFareLockSelected && order.exareInfo.lastExchange) {
        return React.createElement(Redirect, { to: `${baseUrl}/exchange/request` });
    }
    return React.createElement(Passengers, Object.assign({}, props));
};
export default PassengersRoute;
