import React from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyComponent from '@websky/core/src/SearchForm/components/SearchForm/Currency/Currency';
import css from './Currency.css';
const Currency = () => {
    const { t } = useTranslation('SearchForm');
    return (React.createElement("div", { className: css.container },
        React.createElement("span", { className: css.label }, t('Currency')),
        React.createElement(CurrencyComponent, null)));
};
export default Currency;
