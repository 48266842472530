import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from '@websky/core/src';
import { useConfig } from '@websky/core/src/context';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@websky/core/src/theme';
import FareConditions from '@websky/core/src/FareConditions/components/Component';
import Modal from '@websky/core/src/Modal';
import { getOrder } from '@websky/core/src/Checkout/store/order/selectors';
const PassengersToolbarCheckbox = ({ order }) => {
    const { t } = useTranslation('Checkout');
    const { PassengersToolbar: css } = useTheme('Checkout');
    const { global: { companyInfo } } = useConfig();
    const [isFareModalOpen, setIsFareModalOpen] = useState(false);
    const openFareModal = (e) => {
        e.preventDefault();
        setIsFareModalOpen(true);
    };
    const closeFareModal = () => {
        setIsFareModalOpen(false);
    };
    return (React.createElement("span", null,
        t('I accept the'),
        ' ',
        companyInfo.fareRulesURL && (React.createElement(React.Fragment, null,
            React.createElement(Link, { target: "_blank", action: `${companyInfo.fareRulesURL}` }, t('fare rules')),
            ",\u00A0")),
        React.createElement("span", { className: css.terms, onClick: openFareModal }, t('terms of tariff')),
        ', ',
        companyInfo.legal ? (React.createElement(React.Fragment, null,
            React.createElement(Link, { target: "_blank", action: `${companyInfo.legal}` }, t('terms of agreement')),
            companyInfo.privacyPolicy && (React.createElement(React.Fragment, null,
                "\u00A0",
                React.createElement("span", null, t('and')),
                "\u00A0",
                React.createElement(Link, { target: "_blank", action: `${companyInfo.privacyPolicy}` }, t('processing of my personal data')))))) : (t('terms of agreement')),
        React.createElement(Modal, { open: isFareModalOpen, onClose: closeFareModal },
            React.createElement(FareConditions, { segmentId: order.flight.segments[0].segment.id, flightId: order.flight.id, orderId: order.id }))));
};
const mapStateToProps = (state) => ({
    order: getOrder(state)
});
const connector = connect(mapStateToProps);
export default connector(PassengersToolbarCheckbox);
