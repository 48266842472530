import React from 'react';
import { useTranslation } from 'react-i18next';
import PassengersComponent from '@websky/core/src/SearchForm/components/SearchForm/Passengers/Passengers';
import css from './Passengers.css';
const Passengers = props => {
    const { t } = useTranslation('SearchForm');
    return (React.createElement("div", { className: css.container },
        React.createElement("span", { className: css.label }, t('Passengers count')),
        React.createElement(PassengersComponent, Object.assign({}, props))));
};
export default Passengers;
