import { __rest } from "tslib";
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Request from '@websky/core/src/Modules/Exchange/components/Exchange/Request/Request';
import Modal from '@websky/core/src/Modal';
import ResultsError from '@websky/core/src/ResultsError';
import Button from '@websky/core/src/Button';
import { useToggleable } from '@websky/core/src/hooks';
import { getSelectedPassengerIds } from '@websky/core/src/Modules/Exchange/store/passengers/selectors';
import { getOrderId } from '@websky/core/src/Checkout/store/order/selectors';
import { useGetExchangePriceQuery } from '@websky/graphql';
import css from './ExchangeRequest.css';
const ExchangeRequest = (_a) => {
    var _b, _c;
    var { flightId } = _a, props = __rest(_a, ["flightId"]);
    const { t } = useTranslation('Exchange');
    const { isOpen, open, close } = useToggleable(false);
    const orderId = useSelector(getOrderId);
    const selectedPassengersIds = useSelector(getSelectedPassengerIds);
    const exchangePriceVariables = {
        params: {
            flightId,
            orderId,
            passengers: selectedPassengersIds.map(id => +id)
        }
    };
    const { data, loading, error } = useGetExchangePriceQuery({
        fetchPolicy: 'no-cache',
        variables: exchangePriceVariables
    });
    useEffect(() => {
        if (error) {
            open();
        }
    }, [error]);
    const newFlightId = ((_c = (_b = data === null || data === void 0 ? void 0 : data.ExchangePrice) === null || _b === void 0 ? void 0 : _b.exchangeVariantPriceInfo) === null || _c === void 0 ? void 0 : _c[0].flightId) || flightId;
    const prices = React.useMemo(() => {
        var _a, _b, _c, _d;
        if ((_b = (_a = data === null || data === void 0 ? void 0 : data.ExchangePrice) === null || _a === void 0 ? void 0 : _a.exchangeVariantPriceInfo) === null || _b === void 0 ? void 0 : _b[0]) {
            const exchangePrices = data.ExchangePrice.exchangeVariantPriceInfo[0];
            return {
                priceToPay: exchangePrices.priceToPay,
                charges: {
                    amount: +((_c = exchangePrices.agencyChargesForExare) === null || _c === void 0 ? void 0 : _c.amount) + +((_d = exchangePrices.exchangeAirlinePenalty) === null || _d === void 0 ? void 0 : _d.amount),
                    currency: exchangePrices.priceToPay.currency
                }
            };
        }
        return null;
    }, [data === null || data === void 0 ? void 0 : data.ExchangePrice]);
    const onCloseHandler = () => {
        close();
        props.goToFlights();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Request, Object.assign({}, props, { flightId: newFlightId, exchangePrices: prices, isLoading: loading })),
        error && (React.createElement(Modal, { classes: {
                paperScrollBody: css.paperScrollBody
            }, maxWidth: "sm", open: isOpen, onClose: onCloseHandler },
            React.createElement(ResultsError, { header: t('An error has occurred'), body: React.createElement("div", null,
                    React.createElement("p", null, error.message),
                    React.createElement(Button, { onClick: onCloseHandler }, t('Confirm'))) })))));
};
export default ExchangeRequest;
