import React from 'react';
import { useTranslation } from 'react-i18next';
import RetrieveBooking from '@websky/core/src/RetrieveBooking/components/RetrieveBooking';
import { useConfig } from '@websky/core/src/context';
const CheckoutRetrieveBooking = props => {
    const { t } = useTranslation('RetrieveBooking');
    const { PASSENGER_LAST_NAME_LATIN_REGEX, PNR_OR_TICKET_NUMBER_REGEX } = useConfig().global.regex;
    const secretValidation = (value) => {
        if (!PASSENGER_LAST_NAME_LATIN_REGEX.test(value)) {
            return t('Enter latin symbols');
        }
        return undefined;
    };
    const bookingIdValidation = (value) => {
        if (!PNR_OR_TICKET_NUMBER_REGEX.test(value)) {
            return t('Enter 6 latin symbols');
        }
        return undefined;
    };
    const ticketNumberFormat = (value) => {
        return value === null || value === void 0 ? void 0 : value.split(' ').join('').slice(0, 6).toUpperCase();
    };
    return (React.createElement(RetrieveBooking, Object.assign({}, props, { secretValidation: secretValidation, bookingIdValidation: bookingIdValidation, ticketNumberFormat: ticketNumberFormat })));
};
export default CheckoutRetrieveBooking;
