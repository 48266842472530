import * as React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from '@websky/core/src';
import { useConfig } from '@websky/core/src/context';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@websky/core/src/theme';
import FareConditions from '@websky/core/src/FareConditions/components/Component';
import Modal from '@websky/core/src/Modal';
const RequestFormLabel = ({ flightId, orderId }) => {
    const { t } = useTranslation('Refund');
    const { RequestForm: css } = useTheme('Refund');
    const { global: { companyInfo: { exarePrivacyPolicyURL } } } = useConfig();
    const [isFareModalOpen, setIsFareModalOpen] = useState(false);
    const openFareModal = (e) => {
        e.preventDefault();
        setIsFareModalOpen(true);
    };
    const closeFareModal = () => {
        setIsFareModalOpen(false);
    };
    return (React.createElement("span", null,
        t('I agree with the'),
        "\u00A0",
        React.createElement("span", { className: css.terms, onClick: openFareModal }, t('terms of tariff')),
        exarePrivacyPolicyURL && (React.createElement(React.Fragment, null,
            "\u00A0",
            t('And'),
            "\u00A0",
            React.createElement(Link, { target: '_blank', action: exarePrivacyPolicyURL }, t('Privacy Policy')))),
        React.createElement(Modal, { open: isFareModalOpen, onClose: closeFareModal },
            React.createElement(FareConditions, { flightId: flightId, orderId: orderId }))));
};
const isRefundState = (state) => {
    return state.orderId !== undefined;
};
const mapStateToProps = (state) => ({
    flightId: isRefundState(state) ? state.flightId : state.order.flight.id,
    orderId: isRefundState(state) ? state.orderId : state.order.id
});
const connector = connect(mapStateToProps);
export default connector(RequestFormLabel);
